<!--
 * @Description: 原图上传，多图上传，不需要剪裁
 * @Author: 琢磨先生
 * @Date: 2022-06-11 14:33:39
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-07-26 11:59:37
-->

<template>
  <div class="btn_upload" @click="dialogVisible = true">
    <el-icon class="upload-icon"><Upload /></el-icon>
    <div class="text">上传图片</div>
  </div>
  <el-dialog
    title="上传图片"
    v-model="dialogVisible"
    width="650px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    custom-class="psd-dialog"
    @close="dialogClose"
  >
    <div class="help-block">支持png,jpg,jpeg格式图片,且图片大小不能超过10M</div>
    <el-upload
      ref="upload"
      class="original-upload"
      drag
      action=""
      accept=".jpg,.jpeg,.png,.gif"
      :http-request="upload"
      multiple
      :limit="count"
      :before-remove="
        () => {
          return false;
        }
      "
      :before-upload="beforeUpload"
    >
      <el-icon class="el-icon--upload"><upload-filled /></el-icon>
      <div class="el-upload__text">
        拖拽至此 或<em>点击</em>上传，您还可以上传{{ count }}张
      </div>
      <template #tip> </template>
    </el-upload>
  </el-dialog>
</template>

<script>
// import ObsClient from "@/obs/esdk-obs-browserjs.3.22.3.min.js";
import ObsClient from "esdk-obs-browserjs/src/obs";

import md5_util from "@/utils/md5_util.js";
import img_utils from "@/utils/img_utils";

export default {
  data() {
    return {
      dialogVisible: false,
    };
  },
  emits: ["close", "change"],
  props: ["folder", "hook", "count"],
  created() {
    var settings = this.$store.getters.getSettings;
    this.bucketName = settings.obs_bucket_name;
    this.obs_cdn = settings.obs_cdn;
    this.obs = new ObsClient({
      access_key_id: settings.obs_key_id,
      secret_access_key: settings.obs_access_key,
      server: settings.obs_server,
      timeout: 60 * 5,
    });
  },
  methods: {
    beforeUpload(file) {
      // var isR5M = file.size > 1024 * 1024 * 10;
      // if (isR5M) {
      //   this.$message({
      //     type: "error",
      //     message: "图片大小超过10M",
      //   });
      //   return false;
      // }
      return new Promise((resolve) => {
        let img = new Image();
        img.onload = function () {
          file.width = img.width;
          file.height = img.height;
          resolve(file);
        };
        img.src = URL.createObjectURL(file);
      });
    },
    /**
     * 执行上传
     */
    upload: async function (ctx) {
      ctx.onProgress({ percent: 0 });

      var _f = ctx.file;
      //大于2M进行图片压缩
      if (ctx.file.size > 1024 * 1024 * 2) {
        _f = await img_utils.compress(ctx.file);
        _f.name = ctx.file.name;
      }

      var model = {
        height: ctx.file.height,
        width: ctx.file.width,
        size: ctx.file.size,
        psd_type: 1,
        name: ctx.file.name,
        ext: `.${_f.type.replace("image/", "")}`,
      };
      if (this.folder && this.folder.id) {
        model.father_id = this.folder.id;
      }

      //获取md5值
      var md5 = await md5_util.fileMd5(_f);
      model.md5 = md5;

      var res = await this.$http.get(`seller/v1/psd/file/dtl?md5=${md5}`);
      if (res.code == 0) {
        if (res.data) {
          ctx.onSuccess();
          this.$emit("change", res.data, this.hook);
          return;
        }
      }
      //获取文件名称
      res = await this.$http.get("/common/filename");
      if (res.code == 0) {
        var result = await this.obs.putObject({
          Bucket: this.bucketName,
          Key: res.data + model.ext,
          SourceFile: _f,
          ProgressCallback: (transferredAmount, totalAmount) => {
            var percent = (transferredAmount * 100.0) / totalAmount;
            ctx.onProgress({
              percent: percent,
            });
          },
        });
        if (result.CommonMsg.Status < 300) {
          var cdn_url = `${this.obs_cdn}/${res.data}${model.ext}`;
          model.file_url = cdn_url;
          this.$http.post("seller/v1/psd/file/add", model).then((res) => {
            if (res.code == 0) {
              ctx.onSuccess();
              this.$emit("change", res.data, this.hook);
            } else {
              ctx.onError();
            }
          });
        }
      }
    },
    /**
     *
     */
    dialogClose() {
      this.$refs.upload.clearFiles();
      this.$emit("close");
    },
  },
};
</script>

<style >
.btn_upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
}

.btn_upload:hover {
  border-color: var(--el-color-primary);
}

.btn_upload .text {
  font-size: 12px;
  color: #c0c4cc;
  line-height: 12px;
  margin-top: 10px;
}

.btn_upload .el-icon.upload-icon {
  font-size: 26px;
  color: #c0c4cc;
  text-align: center;
}

.original-upload {
  width: 100%;
}
.el-upload {
  width: 100%;
  margin-top: 10px;
}

.original-upload .el-upload.is-drag {
  display: block;
}
.original-upload .el-upload .el-upload-dragger {
  width: 100% !important;
}

.original-upload .el-icon.el-icon--close {
  display: none;
}

/* .psd-dialog .el-upload-list__item {
  padding: 0 10px;
} */
.psd-dialog .el-upload-list__item-thumbnail {
  width: 50px;
  height: 50px;
}
</style>
